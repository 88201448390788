body {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  background-color: grey;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center; /* This centers the items vertically */
  width: 100vw; /* This ensures the header takes the full width */
  padding: 5px; /* Add some padding for better appearance */
  background-color: transparent;
}

.title {
  color: black;
  font-size: xx-large;
}

.info-button {
  cursor: pointer;
  font-size: xx-large;
}

.info {
  margin: 20px;
}